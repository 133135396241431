import BaseApi from './base';

class EmailApi extends BaseApi {
  /**
   * Send reset password email
   * @return {Promise<*>}
   */
  async sendResetPasswordEmail(email) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.SEND_RESET_PASSWORD_EMAIL, { email });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { EmailApi };
