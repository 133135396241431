import BaseApi from './base';
import { isUndefined } from 'lodash';

class CohortApi extends BaseApi {
  /**
   * Get list of cohort
   * @return {Promise<*>}
   */
  async getCohorts({ institution, archived, name }) {
    const _self = this;
    const params = {};
    if (!isUndefined(institution)) params.institution = institution;
    if (!isUndefined(archived)) params.archived = archived;
    if (!isUndefined(name)) params.name = name;

    try {
      const res = await _self.client.get(_self.endpoints.COHORTS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get cohort
   * @return {Promise<*>}
   */
  async getCohort(id) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.COHORT({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create cohort
   * @return {Promise<*>}
   */
  async createCohort(payload) {
    const _self = this;
    try {
      const body = payload;
      const res = await _self.client.post(_self.endpoints.COHORTS, body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update cohort
   * @return {Promise<*>}
   */
  async updateCohort({ id, body }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.COHORT({ id }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Archive cohort
   * @return {Promise<*>}
   */
  async archiveCohort(id) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.COHORT_ARCHIVE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Un-archive cohort
   * @return {Promise<*>}
   */
  async unArchiveCohort(id) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.COHORT_UN_ARCHIVE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete cohort
   * @return {Promise<*>}
   */
  async deleteCohort(id) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.COHORT({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { CohortApi };
