import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

// Form Schema
const FORM_FIELDS = {
  ...COMMON_FIELDS,
  PREFILL_MAPPERS: 'prefillMappers',
  QUESTION_SET: 'questionSet',
  OPTIONS: 'options',
  TYPE: 'type',
  APST_VOCABULARIES: 'apstVocabularies',
  RUBRIC_VOCABULARIES: 'rubricVocabularies',
};
const FORM_ENUMS = {
  TYPE: {
    BASIC: 'BASIC',
    EXPANDING: 'EXPANDING',
  },
  QUESTION_SET_VARIABLE: {
    TEACHER_NAME: 'TEACHER_NAME',
    STUDENT_ID: 'STUDENT_ID',
    STUDENT_EMAIL: 'STUDENT_EMAIL',
    INSTITUTION_NAME: 'INSTITUTION_NAME',
  },
};

// rule apst Schema
const FORM_APST_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  CONTENT: 'content',
};
// rule break Schema
const FORM_RULE_BREAKS_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  TITLE: 'title',
  APST_LIST: 'apstList',
  STANDARDS: 'standards',
};

// QuestionSet Schema
const FORM_QUESTION_SET_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  DESCRIPTION: 'description',
  LABEL: 'label',
  NAME: 'name',
  QUESTIONS: 'questions',
  TYPE: 'type',
};

// Option Schema
const FORM_OPTIONS_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  DESCRIPTION: 'description',
  VALUE_TYPE: 'valueType',
  VALUES: 'values',
};

// Question Section Schema
const QUESTION_SECTION_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  LABEL: 'label',
  NAME: 'name',
  QUESTIONS: 'questions',
  RUBRICS: 'rubrics',
  TYPE: 'type',
  IS_SECURED: 'isSecured',
};

// Question Schema
const QUESTION_SET_QUESTION_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  DESCRIPTION: 'description',
  FORMULA: 'formula',
  INPUT_TYPE: 'inputType',
  LABEL: 'label',
  RUBRICS: 'rubrics',
  NAME: 'name',
  OPTIONS: 'options',
  QUESTIONS: 'questions',
  READ_ONLY: 'readOnly',
  SHOW_IF: 'showIf',
  TYPE: 'type',
  VALIDATION: 'validation',
  VARIABLE: 'variable',
};
const QUESTION_SET_QUESTION_ENUMS = {
  VARIABLE: {
    COVER: 'COVER',
    SUBJECT_INFO: 'SUBJECT_INFO',
    SUBJECT_NAME: 'SUBJECT_NAME',
    SUBJECT_CODE: 'SUBJECT_CODE',
    SEMESTER: 'SEMESTER',
    PREPARED_BY: 'PREPARED_BY',
    PREPARER_EMAIL: 'PREPARER_EMAIL',
    DEPARTMENT: 'DEPARTMENT',
    VERSION: 'VERSION',
    SUBJECT_LEVEL: 'SUBJECT_LEVEL',
    CREDIT_POINTS: 'CREDIT_POINTS',
    AVAILABILITY: 'AVAILABILITY',
    LOGO: 'LOGO',
    WELCOME: 'WELCOME',
    OVERVIEW: 'OVERVIEW',
    LEARNING_OUTCOMES: 'LEARNING_OUTCOMES',
    GENERIC_SKILLS: 'GENERIC_SKILLS',
    ELIGIBILITY: 'ELIGIBILITY',
    STRATEGIES: 'STRATEGIES',
    PRESCRIBED_RESOURCES: 'PRESCRIBED_RESOURCES',
    SUPPLEMENTARY_READINGS: 'SUPPLEMENTARY_READINGS',
    ADDITIONAL_RESOURCES: 'ADDITIONAL_RESOURCES',
    COORDINATOR: 'COORDINATOR',
    BIO: 'BIO',
    OTHER_TEACHING_STAFF: 'OTHER_TEACHING_STAFF',
    COORDINATOR_EMAIL: 'COORDINATOR_EMAIL',
    COORDINATOR_PHONE: 'COORDINATOR_PHONE',
    HEAD_TUTOR: 'HEAD_TUTOR',
    TUTORS: 'TUTORS',
    CONSULTATION: 'CONSULTATION',
    EMAIL_PROTOCOL: 'EMAIL_PROTOCOL',
    LECTURE_TIMES: 'LECTURE_TIMES',
    LECTURE_PARTICIPATION_REQ: 'LECTURE_PARTICIPATION_REQ',
    LECTURE_SEMINAR: 'LECTURE_SEMINAR',
    WEEKLY_SCHEDULE: 'WEEKLY_SCHEDULE',
    MY_TIMETABLE: 'MY_TIMETABLE',
    RECORDED_LECTURES: 'RECORDED_LECTURES',
    TUTORIAL_WORKSHOP: 'TUTORIAL_WORKSHOP',
    TUTORIAL_TIMES: 'TUTORIAL_TIMES',
    WORKSHOP_TIMES: 'WORKSHOP_TIMES',
    TUTORIAL_PARTICIPATION_REQ: 'TUTORIAL_PARTICIPATION_REQ',
    WORKSHOP_PARTICIPATION_REQ: 'WORKSHOP_PARTICIPATION_REQ',
    TUTORIAL_EXERCISES: 'TUTORIAL_EXERCISES',
    PRIVATE_TUTORING: 'PRIVATE_TUTORING',
    ASSESSMENT_SUMMARY: 'ASSESSMENT_SUMMARY',
    FEEDBACK_ON_ASSESSMENT: 'FEEDBACK_ON_ASSESSMENT',
    A1_NAME_OF_ASSESSMENT: 'A1_NAME_OF_ASSESSMENT',
    A1_VALUE_OF_ASSESSMENT: 'A1_VALUE_OF_ASSESSMENT',
    A1_GROUP_OR_INDIVIDUAL: 'A1_GROUP_OR_INDIVIDUAL',
    A1_DUE_DATE: 'A1_DUE_DATE',
    A1_INSTRUCTIONS: 'A1_INSTRUCTIONS',
    A1_PRESENTATION_FORMAT: 'A1_PRESENTATION_FORMAT',
    A1_HOW_TO_SUBMIT: 'A1_HOW_TO_SUBMIT',
    A2_NAME_OF_ASSESSMENT: 'A2_NAME_OF_ASSESSMENT',
    A2_VALUE_OF_ASSESSMENT: 'A2_VALUE_OF_ASSESSMENT',
    A2_GROUP_OR_INDIVIDUAL: 'A2_GROUP_OR_INDIVIDUAL',
    A2_DUE_DATE: 'A2_DUE_DATE',
    A2_INSTRUCTIONS: 'A2_INSTRUCTIONS',
    A2_PRESENTATION_FORMAT: 'A2_PRESENTATION_FORMAT',
    A2_HOW_TO_SUBMIT: 'A2_HOW_TO_SUBMIT',
    A3_NAME_OF_ASSESSMENT: 'A3_NAME_OF_ASSESSMENT',
    A3_VALUE_OF_ASSESSMENT: 'A3_VALUE_OF_ASSESSMENT',
    A3_GROUP_OR_INDIVIDUAL: 'A3_GROUP_OR_INDIVIDUAL',
    A3_DUE_DATE: 'A3_DUE_DATE',
    A3_INSTRUCTIONS: 'A3_INSTRUCTIONS',
    A3_PRESENTATION_FORMAT: 'A3_PRESENTATION_FORMAT',
    A3_HOW_TO_SUBMIT: 'A3_HOW_TO_SUBMIT',
    A4_NAME_OF_ASSESSMENT: 'A4_NAME_OF_ASSESSMENT',
    A4_VALUE_OF_ASSESSMENT: 'A4_VALUE_OF_ASSESSMENT',
    A4_GROUP_OR_INDIVIDUAL: 'A4_GROUP_OR_INDIVIDUAL',
    A4_DUE_DATE: 'A4_DUE_DATE',
    A4_INSTRUCTIONS: 'A4_INSTRUCTIONS',
    A4_PRESENTATION_FORMAT: 'A4_PRESENTATION_FORMAT',
    A4_HOW_TO_SUBMIT: 'A4_HOW_TO_SUBMIT',
    ASSIGNMENT_SUBMISSION: 'ASSIGNMENT_SUBMISSION',
    LATE_SUBMISSION: 'LATE_SUBMISSION',
    HURDLE_FOR_SUBJECT: 'HURDLE_FOR_SUBJECT',
    HURDLE: 'HURDLE',
    OTHER_INSTRUCTIONS: 'OTHER_INSTRUCTIONS',
    APPLYING_FOR_SPECIAL_CONSIDERATION: 'APPLYING_FOR_SPECIAL_CONSIDERATION',
    HONESTY: 'HONESTY',
    PLAGIARISM: 'PLAGIARISM',
    REFERENCING: 'REFERENCING',
    ASSESSMENT_AND_RESULTS_POLICY: 'ASSESSMENT_AND_RESULTS_POLICY',
    ASSIGNMENT_EXTENSION: 'ASSIGNMENT_EXTENSION',
    EXAM_POLICY: 'EXAM_POLICY',
    CALCULATORS: 'CALCULATORS',
    SPECIAL_CONSIDERATION: 'SPECIAL_CONSIDERATION',
    AEA: 'AEA',
    STOP_1: 'STOP_1',
    SERVICE_FINDER: 'SERVICE_FINDER',
    ACADEMIC_SKILLS: 'ACADEMIC_SKILLS',
    COUNSELLING: 'COUNSELLING',
    EQUITY: 'EQUITY',
    LIBRARY: 'LIBRARY',
  },
};
export {
  FORM_FIELDS,
  FORM_ENUMS,
  FORM_OPTIONS_FIELDS,
  FORM_QUESTION_SET_FIELDS,
  QUESTION_SECTION_FIELDS,
  QUESTION_SET_QUESTION_FIELDS,
  QUESTION_SET_QUESTION_ENUMS,
  FORM_APST_FIELDS,
  FORM_RULE_BREAKS_FIELDS,
};
