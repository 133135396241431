import { createAsyncThunk } from '@reduxjs/toolkit';
import { cohortApi } from '@client/services/api';
import { ARCHIVE_COHORT, CREATE_COHORT, DELETE_COHORT, GET_COHORT, GET_COHORTS, UN_ARCHIVE_COHORT, UPDATE_COHORT } from '@client/store/types/cohort';

/**
 * Get cohorts
 */
const getCohorts = createAsyncThunk(GET_COHORTS, async ({ institution, archived, name }) => {
  return await cohortApi.getCohorts({ institution, archived, name });
});

/**
 * Get cohort
 */
const getCohort = createAsyncThunk(GET_COHORT, async id => {
  return await cohortApi.getCohort(id);
});

/**
 * Create cohort
 */
const createCohort = createAsyncThunk(CREATE_COHORT, async payload => {
  return await cohortApi.createCohort(payload);
});

/**
 * Update cohort
 */
const updateCohort = createAsyncThunk(UPDATE_COHORT, async ({ id, body }) => {
  return await cohortApi.updateCohort({ id, body });
});

/**
 * Archive cohort
 */
const archiveCohort = createAsyncThunk(ARCHIVE_COHORT, async id => {
  return await cohortApi.archiveCohort(id);
});

/**
 * Un-archive cohort
 */
const unArchiveCohort = createAsyncThunk(UN_ARCHIVE_COHORT, async id => {
  return await cohortApi.unArchiveCohort(id);
});

/**
 * Delete cohort
 */
const deleteCohort = createAsyncThunk(DELETE_COHORT, async payload => {
  await cohortApi.deleteCohort(payload);
  return payload;
});

export { getCohorts, getCohort, createCohort, updateCohort, deleteCohort, archiveCohort, unArchiveCohort };
