import React from 'react';
import { ROOT_ROUTES } from '@client/constants/routes';
import { RoutePathWrapper } from '@client/styles/wrapper';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { REDUCER_STATE } from '@client/constants/store';
import { INSTITUTION_FIELDS } from '@client/constants/db-fields';

const PathBreadcrumb = () => {
  const location = useLocation();
  const institution = useSelector(state => state?.[REDUCER_STATE.INSTITUTION.NAME]?.[REDUCER_STATE.INSTITUTION.FIELDS.INSTITUTION]);
  const path = location?.pathname?.split('')?.join('');
  if (path === ROOT_ROUTES.HOME) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>Dashboard</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  } else if (!isEmpty(matchPath(path, { path: ROOT_ROUTES.USER, exact: true, strict: false }))) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>Profile</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  } else if (path.includes(ROOT_ROUTES.INSTITUTIONS)) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>Institutions</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  } else if (path.includes(ROOT_ROUTES.COHORTS)) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>
          <Link to={ROOT_ROUTES.INSTITUTIONS}>Institutions</Link>
        </RoutePathWrapper.Item>
        <RoutePathWrapper.Item>{institution?.[INSTITUTION_FIELDS.NAME]}</RoutePathWrapper.Item>
        <RoutePathWrapper.Item>Cohorts</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  } else if (path.includes(ROOT_ROUTES.ASSESSMENTS)) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>Assessments</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  } else if (path === ROOT_ROUTES.USERS) {
    return (
      <RoutePathWrapper>
        <RoutePathWrapper.Item>
          <Link to={ROOT_ROUTES.INSTITUTIONS}>Institutions</Link>
        </RoutePathWrapper.Item>
        <RoutePathWrapper.Item>Users</RoutePathWrapper.Item>
      </RoutePathWrapper>
    );
  }
};

export default PathBreadcrumb;
