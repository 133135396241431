import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { findIndex } from 'lodash';
import {
  archiveInstitution,
  createInstitution,
  deleteInstitution,
  getInstitution,
  getInstitutions,
  unArchiveInstitution,
  updateInstitution,
} from '@client/store/actions/institution';
import { INSTITUTION_FIELDS } from '@client/constants/db-fields/institution';

const {
  INSTITUTION: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING]: false,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_UPDATING]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.INSTITUTIONS]: [],
  [FIELDS.INSTITUTION]: null,
};

const reducers = {
  setCurrentInstitution: (state, action) => {
    state[FIELDS.INSTITUTION] = action.payload;
  },
};

const extraReducers = {
  [getInstitutions.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.INSTITUTIONS]: null,
  }),
  [getInstitutions.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.INSTITUTIONS]: action.payload,
  }),
  [getInstitutions.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.INSTITUTIONS]: null,
  }),

  [getInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.INSTITUTION]: null,
  }),
  [getInstitution.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.INSTITUTION]: action.payload,
  }),
  [getInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.INSTITUTION]: null,
  }),

  [createInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createInstitution.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.INSTITUTIONS];
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.INSTITUTIONS]: [action.payload, ...existed],
    };
  },
  [createInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_CREATING]: false,
  }),

  [updateInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [updateInstitution.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.INSTITUTIONS];
    const id = action?.payload?.[INSTITUTION_FIELDS.ID];
    const index = findIndex(existed, { [INSTITUTION_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.INSTITUTIONS]: newList,
    };
  },
  [updateInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [archiveInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [archiveInstitution.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.INSTITUTIONS];
    const id = action?.payload?.[INSTITUTION_FIELDS.ID];
    const index = findIndex(existed, { [INSTITUTION_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.INSTITUTIONS]: newList,
    };
  },
  [archiveInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [unArchiveInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [unArchiveInstitution.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.INSTITUTIONS];
    const id = action?.payload?.[INSTITUTION_FIELDS.ID];
    const index = findIndex(existed, { [INSTITUTION_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.INSTITUTIONS]: newList,
    };
  },
  [unArchiveInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [deleteInstitution.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteInstitution.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.INSTITUTIONS];
    const deletedId = action.payload;
    const newList = existed?.filter(each => each?.[INSTITUTION_FIELDS.ID] !== deletedId);
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.INSTITUTIONS]: newList,
    };
  },
  [deleteInstitution.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_DELETING]: false,
  }),
};

const institutionSlice = createSlice({
  name: REDUCER_STATE.INSTITUTION.NAME,
  initialState,
  reducers,
  extraReducers,
});

const { setCurrentInstitution } = institutionSlice.actions;
export { institutionSlice, setCurrentInstitution };
