import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { findIndex } from 'lodash';
import { archiveCohort, createCohort, deleteCohort, getCohort, getCohorts, unArchiveCohort, updateCohort } from '@client/store/actions/cohort';
import { COHORT_FIELDS } from '@client/constants/db-fields';

const {
  COHORT: { FIELDS },
} = REDUCER_STATE;

const reducers = {
  setCurrentCohort: (state, action) => {
    state[FIELDS.COHORT] = action.payload;
  },
};

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING]: false,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_UPDATING]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.COHORTS]: [],
  [FIELDS.COHORT]: null,
};
const extraReducers = {
  [getCohorts.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.COHORTS]: null,
  }),
  [getCohorts.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COHORTS]: action.payload,
  }),
  [getCohorts.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COHORTS]: null,
  }),

  [getCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.COHORT]: null,
  }),
  [getCohort.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COHORT]: action.payload,
  }),
  [getCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COHORT]: null,
  }),

  [createCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createCohort.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.COHORTS];
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.COHORTS]: [action.payload, ...existed],
    };
  },
  [createCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_CREATING]: false,
  }),

  [updateCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [updateCohort.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.COHORTS];
    const id = action?.payload?.[COHORT_FIELDS.ID];
    const index = findIndex(existed, { [COHORT_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.COHORTS]: newList,
    };
  },
  [updateCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [archiveCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [archiveCohort.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.COHORTS];
    const id = action?.payload?.[COHORT_FIELDS.ID];
    const index = findIndex(existed, { [COHORT_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.COHORTS]: newList,
    };
  },
  [archiveCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [unArchiveCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [unArchiveCohort.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.COHORTS];
    const id = action?.payload?.[COHORT_FIELDS.ID];
    const index = findIndex(existed, { [COHORT_FIELDS.ID]: id });
    let newList = [...existed];
    if (index !== -1) {
      newList = [...existed.slice(0, index), action.payload, ...existed.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.COHORTS]: newList,
    };
  },
  [unArchiveCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING]: false,
  }),

  [deleteCohort.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteCohort.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.COHORTS];
    const deletedId = action.payload;
    const newList = existed?.filter(each => each?.[COHORT_FIELDS.ID] !== deletedId);
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.COHORTS]: newList,
    };
  },
  [deleteCohort.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_DELETING]: false,
  }),
};

const cohortSlice = createSlice({
  name: REDUCER_STATE.COHORT.NAME,
  initialState,
  reducers,
  extraReducers,
});

const { setCurrentCohort } = cohortSlice.actions;

export { cohortSlice, setCurrentCohort };
