import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUCER_STATE } from '@client/constants/store';
import Loading from '@client/components/loading';
import { getCurrentUser, logout } from '@client/store/actions/auth';
import { generatePath, matchPath, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ASSESSMENTS_ROUTES, ROOT_ROUTES } from '@client/constants/routes';
import { Avatar, Menu, Popconfirm, Space, Tag } from 'antd';
import { MESSAGE } from '@client/constants/message';
import {
  AuthContentLayout,
  AuthLayout,
  AuthLayoutContent,
  AuthLayoutCornerHeader,
  AuthLayoutHeader,
  AuthLayoutSideFooter,
  AuthLayoutSider,
  MenuControlWrapper,
} from '@client/styles/layout';
import { CenterWrapper, IconWrapper } from '@client/styles/wrapper';
import { Book2, Dashboard, LayoutCards } from '@ricons/tabler';
import { AppLogo, UniversityLogo } from '@client/components/logo';
import { DEVELOPED_BY, USER_ROLE_TAG_COLOR } from '@client/constants';
import { USER_ENUMS, USER_FIELDS } from '@client/constants/db-fields';
import { IdcardOutlined, LogoutOutlined } from '@ant-design/icons';
import { PERMISSION_TYPES, PERMISSIONS } from '@client/constants/permissions';
import PathBreadcrumb from '@client/components/reused-component/path-breadcrumb';
import PathTooltip from '@client/components/reused-component/path-tooltip';
import { isEmpty } from 'lodash';
import { checkPermission } from '@client/libs/permission';

const AuthRouteWrapper = ({ component: Component, ...otherProps }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const isVerifying = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_VERIFYING]);
  const isLoggedIn = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]);
  const currentUser = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.DATA])?.user;
  const version = useSelector(state => state?.[REDUCER_STATE.GENERAL.NAME]?.[REDUCER_STATE.GENERAL.FIELDS.VERSION]);

  const MENU_ITEM_KEY = {
    HOME: 'HOME',
    ASSESSMENTS: 'ASSESSMENTS',
    INSTITUTIONS: 'INSTITUTIONS',
  };
  const MENU_ITEM = {
    DASHBOARD: {
      key: MENU_ITEM_KEY.HOME,
      name: 'Dashboard',
      icon: (
        <IconWrapper>
          <Dashboard />
        </IconWrapper>
      ),
      isVisible: true,
    },
    INSTITUTIONS: {
      key: MENU_ITEM_KEY.INSTITUTIONS,
      icon: (
        <IconWrapper>
          <LayoutCards />
        </IconWrapper>
      ),
      name: 'Institutions',
      isVisible: PERMISSIONS?.[currentUser?.[USER_FIELDS.ROLE]]?.[PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS],
    },
    ASSESSMENTS: {
      key: MENU_ITEM_KEY.ASSESSMENTS,
      icon: (
        <IconWrapper>
          <Book2 />
        </IconWrapper>
      ),
      name: 'Assessments',
      isVisible: PERMISSIONS?.[currentUser?.[USER_FIELDS.ROLE]]?.[PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS],
    },
  };

  const [selectedKey, setSelectedKey] = useState(MENU_ITEM_KEY.HOME);

  /**
   * Handle click menu item
   * @param {string} key - menu item key
   */
  const handleClickMenu = key => {
    setSelectedKey(key);
    if (key === MENU_ITEM_KEY.HOME) {
      history.push(ROOT_ROUTES.HOME);
    } else if (key === MENU_ITEM_KEY.ASSESSMENTS) {
      history.push(ROOT_ROUTES.ASSESSMENTS);
    } else if (key === MENU_ITEM_KEY.INSTITUTIONS) {
      history.push(ROOT_ROUTES.INSTITUTIONS);
    }
  };

  /**
   * Handle logout
   */
  const handleLogout = () => {
    dispatch(logout());
  };

  /**
   * Check permission to enter this route
   * @return {boolean}
   */
  useEffect(() => {
    if (!isVerifying && !isEmpty(currentUser)) {
      let isBackToHome = false;
      if (
        pathname === ROOT_ROUTES.USERS &&
        !checkPermission({ role: currentUser?.[USER_FIELDS.ROLE], permission: PERMISSION_TYPES.CAN_VIEW_USERS })
      ) {
        isBackToHome = true;
      } else if (
        pathname === ROOT_ROUTES.ASSESSMENTS &&
        !checkPermission({ role: currentUser?.[USER_FIELDS.ROLE], permission: PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS })
      ) {
        isBackToHome = true;
      } else if (
        pathname === ROOT_ROUTES.INSTITUTIONS &&
        !checkPermission({
          role: currentUser?.[USER_FIELDS.ROLE],
          permission: PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS,
        })
      ) {
        isBackToHome = true;
      } else if (
        pathname === ROOT_ROUTES.COHORTS &&
        !checkPermission({ role: currentUser?.[USER_FIELDS.ROLE], permission: PERMISSION_TYPES.CAN_MANAGE_COHORTS })
      ) {
        isBackToHome = true;
      } else if (
        !isEmpty(matchPath(pathname, { path: ASSESSMENTS_ROUTES.NEW_FORM, exact: true, strict: false })) &&
        !checkPermission({
          role: currentUser?.[USER_FIELDS.ROLE],
          permission: PERMISSION_TYPES.CAN_START_ASSESSMENTS,
        }) &&
        currentUser?.[USER_FIELDS.ROLE] !== USER_ENUMS.ROLE.ADMIN
      ) {
        // Only student can create new assessment answer
        isBackToHome = true;
      }
      if (isBackToHome) {
        history.push(ROOT_ROUTES.HOME);
      }
    }
  }, [pathname, currentUser, history, isVerifying]);

  /**
   * Set default selected key according to pathname
   */
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === ROOT_ROUTES.HOME) {
      setSelectedKey(MENU_ITEM_KEY.HOME);
    } else if (!isEmpty(matchPath(pathname, { path: ROOT_ROUTES.ASSESSMENTS, exact: false, strict: false }))) {
      setSelectedKey(MENU_ITEM_KEY.ASSESSMENTS);
    } else if (!isEmpty(matchPath(pathname, { path: ROOT_ROUTES.INSTITUTIONS, exact: false, strict: false }))) {
      // Institution page will active institution tab
      setSelectedKey(MENU_ITEM_KEY.INSTITUTIONS);
    } else if (!isEmpty(matchPath(pathname, { path: ROOT_ROUTES.USERS, exact: false, strict: false }))) {
      // Users page will active institution tab
      setSelectedKey(MENU_ITEM_KEY.INSTITUTIONS);
    } else if (!isEmpty(matchPath(pathname, { path: ROOT_ROUTES.COHORTS, exact: false, strict: false }))) {
      // Users page will active institution tab
      setSelectedKey(MENU_ITEM_KEY.INSTITUTIONS);
    }
  }, [location.pathname, MENU_ITEM_KEY.HOME, MENU_ITEM_KEY.ASSESSMENTS, MENU_ITEM_KEY.INSTITUTIONS]);

  /**
   * Check auth when route change
   */
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch, pathname]);

  if (isVerifying) {
    return <Loading fullscreen={false} tip={MESSAGE.LOADING.DEFAULT} />;
  } else {
    if (isLoggedIn) {
      return (
        <AuthLayout>
          <AuthLayoutSider>
            <AuthLayoutCornerHeader>
              <CenterWrapper>
                <div className={'logo'}>
                  <AppLogo />
                </div>
              </CenterWrapper>
            </AuthLayoutCornerHeader>
            <MenuControlWrapper>
              <Menu
                mode='inline'
                defaultSelectedKeys={[MENU_ITEM_KEY.DASHBOARD]}
                onClick={e => handleClickMenu(e?.key)}
                selectedKeys={[selectedKey]}
                items={Object.keys(MENU_ITEM)?.map(each => {
                  if (MENU_ITEM?.[each]?.isVisible) {
                    return {
                      label: MENU_ITEM?.[each]?.name,
                      key: MENU_ITEM?.[each]?.key,
                      icon: MENU_ITEM?.[each]?.icon,
                    };
                  } else {
                    return null;
                  }
                })}
              />
              <AuthLayoutSideFooter>
                <CenterWrapper>
                  <div className={'logo'}>
                    <UniversityLogo />
                  </div>
                </CenterWrapper>
                <div style={{ textAlign: 'center' }}>{DEVELOPED_BY}</div>
                <div style={{ textAlign: 'center' }}>{version}</div>
              </AuthLayoutSideFooter>
            </MenuControlWrapper>
          </AuthLayoutSider>
          <AuthContentLayout>
            <AuthLayoutHeader>
              {/*Breadcrumb*/}
              <Space align={'center'}>
                <PathBreadcrumb />
                <PathTooltip />
              </Space>
              {/*Login user info*/}
              <div className={'tools'}>
                <Space size={'small'}>
                  <Avatar style={{ backgroundColor: '#87d068' }} size={32}>
                    {currentUser?.[USER_FIELDS.FIRST_NAME]?.slice(0, 1)}
                  </Avatar>
                  {currentUser?.[USER_FIELDS.EMAIL]}{' '}
                  <Tag color={USER_ROLE_TAG_COLOR?.[currentUser?.[USER_FIELDS.ROLE]]}>{currentUser?.[USER_FIELDS.ROLE]}</Tag>
                </Space>
                <IdcardOutlined
                  onClick={() => {
                    const url = generatePath(ROOT_ROUTES.USER, {
                      userId: currentUser?.[USER_FIELDS.ID],
                    });
                    history.push(url);
                  }}
                />
                <Popconfirm
                  title={'Are you sure to logout?'}
                  onConfirm={() => {
                    handleLogout();
                  }}
                >
                  <LogoutOutlined />
                </Popconfirm>
              </div>
            </AuthLayoutHeader>
            <AuthLayoutContent>
              <Route {...otherProps} render={props => <Component {...props} />} />
            </AuthLayoutContent>
          </AuthContentLayout>
        </AuthLayout>
      );
    } else {
      return <Redirect to={{ pathname: ROOT_ROUTES.LOG_IN, state: { redirectedFrom: `${location.pathname}${location.search}` } }} />;
    }
  }
};

AuthRouteWrapper.propTypes = {
  component: PropTypes.object.isRequired,
};

export default AuthRouteWrapper;
