import React, { lazy, Suspense, useEffect } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loading from '@client/components/loading';
import { ROOT_ROUTES } from '@client/constants/routes';
import LoginRouteWrapper from '@client/routes/wrapper/login-route-wrapper';
import { GlobalWrapper } from '@client/styles/wrapper';
import AuthRouteWrapper from '@client/routes/wrapper/auth-route-wrapper';
import PublicRouteWrapper from '@client/routes/wrapper/public-route-wrapper';

import { useDispatch } from 'react-redux';
import { getGitVersion } from '@client/store/actions/general';

const LoginPage = lazy(() => import('@client/pages/login-page'));
const HomePage = lazy(() => import('@client/pages/home-page'));
const UserTablePage = lazy(() => import('@client/pages/user-table-page'));
const AssessmentPage = lazy(() => import('@client/pages/assessments'));
const ProfilePage = lazy(() => import('@client/pages/profile'));
const InstitutionPage = lazy(() => import('@client/pages/institution'));
const CohortPage = lazy(() => import('@client/pages/cohort-page'));
const EmailVerificationPage = lazy(() => import('@client/pages/email-verification-page'));
const ResetPasswordPage = lazy(() => import('@client/pages/reset-password-page'));
const ForgotPasswordPage = lazy(() => import('@client/pages/forgot-password-page'));

const RootRoutes = () => {
  const dispatch = useDispatch();

  /**
   * Get git version
   */
  useEffect(() => {
    dispatch(getGitVersion());
  }, [dispatch]);

  return (
    <HashRouter>
      <GlobalWrapper>
        <Suspense fallback={<Loading fullscreen={true} />}>
          <Switch>
            <LoginRouteWrapper path={ROOT_ROUTES.LOG_IN} component={LoginPage} />
            <AuthRouteWrapper exact path={ROOT_ROUTES.HOME} component={HomePage} />
            <AuthRouteWrapper path={ROOT_ROUTES.USERS} exact component={UserTablePage} />
            <AuthRouteWrapper path={ROOT_ROUTES.USER} component={ProfilePage} />
            <AuthRouteWrapper path={ROOT_ROUTES.ASSESSMENTS} component={AssessmentPage} />
            <AuthRouteWrapper path={ROOT_ROUTES.INSTITUTIONS} component={InstitutionPage} />
            <AuthRouteWrapper path={ROOT_ROUTES.COHORTS} component={CohortPage} />
            <PublicRouteWrapper path={ROOT_ROUTES.EMAIL_VERIFICATION} component={EmailVerificationPage} />
            <PublicRouteWrapper path={ROOT_ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
            <PublicRouteWrapper path={ROOT_ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
            <Route path={ROOT_ROUTES.ALL}>
              <Redirect to={ROOT_ROUTES.HOME} />
            </Route>
          </Switch>
        </Suspense>
      </GlobalWrapper>
    </HashRouter>
  );
};

export default RootRoutes;
