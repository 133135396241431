import { combineReducers } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { authSlice } from '@client/store/reducers/auth';
import { REDUCER_STATE } from '@client/constants/store';
import { userSlice } from '@client/store/reducers/user';
import { formSlice } from '@client/store/reducers/form';
import { institutionSlice } from '@client/store/reducers/institution';
import { cohortSlice } from '@client/store/reducers/cohort';
import { generalSlice } from '@client/store/reducers/general';
import { tokenSlice } from '@client/store/reducers/token';

export const history = createHashHistory();
const createRootReducer = history =>
  combineReducers({
    [REDUCER_STATE.ROUTER.NAME]: connectRouter(history),
    [REDUCER_STATE.AUTH.NAME]: authSlice.reducer,
    [REDUCER_STATE.USER.NAME]: userSlice.reducer,
    [REDUCER_STATE.FORM.NAME]: formSlice.reducer,
    [REDUCER_STATE.INSTITUTION.NAME]: institutionSlice.reducer,
    [REDUCER_STATE.COHORT.NAME]: cohortSlice.reducer,
    [REDUCER_STATE.GENERAL.NAME]: generalSlice.reducer,
    [REDUCER_STATE.TOKEN.NAME]: tokenSlice.reducer,
  });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = function configureStore(preloadedState) {
  const store = createStore(createRootReducer(history), preloadedState, composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)));
  return store;
};
