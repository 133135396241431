export const GET_FORM = 'forms/getForm';
export const GET_FORMS = 'forms/getForms';
export const GET_FORM_ANSWERS = 'forms/getFormAnswers';
export const GET_FORM_ANSWER = 'forms/getFormAnswer';
export const CREATE_FORM_ANSWER = 'forms/createFormAnswer';
export const UPDATE_FORM_ANSWER = 'forms/updateFormAnswer';
export const UPDATE_FORM_ANSWER_STATUS = 'forms/updateFormAnswerStatus';
export const UPDATE_FORM_ANSWER_MARKING_STATUS = 'forms/updateFormAnswerMarkingStatus';
export const DELETE_FORM_ANSWER = 'forms/deleteFormAnswer';
export const GET_PREFILLED_FORM_DATA = 'forms/getPrefilledFormData';
export const DOWNLOAD_FORM_ANSWER_DATA_AS_PDF = 'forms/downloadFormAnswerDataAsPdf';
export const DOWNLOAD_FORM_ANSWER_DATA_AS_HTML = 'forms/downloadFormAnswerDataAsHtml';
export const LOCK_FORM_ANSWER = 'forms/lockFormAnswer';
export const UNLOCK_FORM_ANSWER = 'forms/unlockFormAnswer';
export const MARK_FORM_ANSWER = 'forms/markFormAnswer';
export const RESET_FORM_ANSWER = 'forms/resetFormAnswer';
