import { USER_ENUMS } from '@client/constants/db-fields';
import { PERMISSIONS } from '@client/constants/permissions';

/**
 * Check if role is admin
 * @param {string} role
 * @returns {boolean}
 */
const isAdmin = role => {
  return role === USER_ENUMS.ROLE.ADMIN;
};

/**
 * Check if role is assessor
 * @param {string} role
 * @returns {boolean}
 */
const isAssessor = role => {
  return role === USER_ENUMS.ROLE.ASSESSOR;
};

/**
 * Check if role is student
 * @param {string} role
 * @returns {boolean}
 */
const isStudent = role => {
  return role === USER_ENUMS.ROLE.STUDENT;
};

/**
 * Check if has permisson(s)
 * @param {string} role
 * @param {string|Array} permission
 * @returns {*|boolean|boolean}
 */
const checkPermission = ({ role, permission }) => {
  if (typeof permission === 'string') {
    return PERMISSIONS?.[role]?.[permission] || false;
  } else if (Array.isArray(permission)) {
    let result = true;
    for (let i = 0; i < permission.length; i++) {
      const cur = permission[i];
      if (!PERMISSIONS?.[role]?.[cur]) {
        result = false;
        break;
      }
    }
    return result;
  } else {
    throw new Error(`Invalid type of params ${permission} passed to function.`);
  }
};

export { isAdmin, isAssessor, isStudent, checkPermission };
