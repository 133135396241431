import { AuthApi } from '@client/services/api/modules/auth';
import { UserApi } from '@client/services/api/modules/user';
import FormApi from '@client/services/api/modules/form';
import { InstitutionApi } from '@client/services/api/modules/institution';
import { CohortApi } from '@client/services/api/modules/cohort';
import FileApi from '@client/services/api/modules/file';
import { GeneralApi } from '@client/services/api/modules/general';
import { TokenApi } from '@client/services/api/modules/token';
import { EmailApi } from '@client/services/api/modules/email';

const authApi = new AuthApi();
const userApi = new UserApi();
const formApi = new FormApi();
const institutionApi = new InstitutionApi();
const fileApi = new FileApi();
const cohortApi = new CohortApi();
const generalApi = new GeneralApi();

const tokenApi = new TokenApi();
const emailApi = new EmailApi();

export { authApi, emailApi, userApi, formApi, institutionApi, fileApi, cohortApi, generalApi, tokenApi };
