const DEFAULT_STATE = {
  DATA: 'data',
  ERROR: 'error',
  IS_UPDATING: 'isUpdating',
  IS_GETTING: 'isGetting',
  IS_CREATING: 'isCreating',
  IS_DELETING: 'isDeleting',
};

const REDUCER_STATE = {
  ROUTER: {
    NAME: 'router',
  },
  GENERAL: {
    NAME: 'general',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING: DEFAULT_STATE.IS_GETTING,
      MARKING_DATA: 'markingData',
      COMPLETION_DATA: 'completionData',
      NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA: 'numberOfAssessmentsByStatusData',
      VERSION: 'version',
    },
  },
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      DATA: DEFAULT_STATE.DATA,
      IS_LOGGING_IN: 'isLoggingIn',
      IS_LOGGING_OUT: 'isLoggingOut',
      IS_VERIFYING: 'isVerifying',
      IS_LOGGED_IN: 'isLoggedIn',
      IS_UPDATING_PROFILE: 'isUpdatingProfile',
    },
  },
  INSTITUTION: {
    NAME: 'institution',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_UPDATING: DEFAULT_STATE.IS_UPDATING,
      IS_GETTING: DEFAULT_STATE.IS_GETTING,
      IS_CREATING: DEFAULT_STATE.IS_CREATING,
      IS_DELETING: DEFAULT_STATE.IS_DELETING,
      INSTITUTIONS: 'institutions',
      INSTITUTION: 'institution',
    },
  },
  COHORT: {
    NAME: 'cohort',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_UPDATING: DEFAULT_STATE.IS_UPDATING,
      IS_GETTING: DEFAULT_STATE.IS_GETTING,
      IS_CREATING: DEFAULT_STATE.IS_CREATING,
      IS_DELETING: DEFAULT_STATE.IS_DELETING,
      COHORTS: 'cohorts',
      COHORT: 'cohort',
    },
  },
  USER: {
    NAME: 'user',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_USER: 'isGettingUser',
      IS_CREATING_USER: 'isCreatingUser',
      IS_UPDATING_USER: 'isUpdatingUser',
      IS_RESETTING_PASSWORD: 'isResettingPassword',
      IS_CREATING_USERS: 'isCreatingUsers',
      IS_DELETING_USER: 'isDeletingUser',
      USERS: 'users',
      USER: 'user',
    },
  },
  FORM: {
    NAME: 'form',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      FORM: 'form',
      FORMS: 'forms',
      FORM_ANSWERS: 'formAnswers',
      PDF_DATA: 'pdfData',
      FORM_ANSWER: 'formAnswer',
      PREFILLED_DATA: 'prefilledData',
      HTML_DATA: 'htmlData',
      IS_GETTING_FORM: 'isGettingForm',
      IS_UPDATING_FORM: 'isUpdatingForm',
      IS_GETTING_FORM_ANSWER: 'isGettingFormAnswer',
      IS_UPDATING_FORM_ANSWER: 'isUpdatingFormAnswer',
      IS_MARKING_FORM_ANSWER: 'isMarkingFormAnswer',
      IS_CREATING_FORM_ANSWER: 'isCreatingFormAnswer',
      IS_GETTING_PREFILLED_DATA: 'isGettingPrefilledData',
      IS_DELETING_FORM_ANSWER: 'isDeletingFormAnswer',
      IS_RESETTING_FORM_ANSWER: 'isResettingFormAnswer',
      IS_UPDATING_FORM_ANSWER_STATUS: 'isUpdatingFormAnswerStatus',
      IS_LOCKING: 'isLocking',
      IS_UNLOCKING: 'isUnlocking',
      IS_DOWNLOADING: 'isDownloading',
      IS_DOWNLOADING_HTML: 'isDownloadingHtml',
      FORCE_SWITCH_URL: 'forceSwitchUrl',
    },
  },
  TOKEN: {
    NAME: 'token',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_VERIFYING: 'isVerifying',
      IS_VERIFIED: 'isVerified',
      TOKEN: 'token',
    },
  },
};

export { REDUCER_STATE };
