import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles/index.module.less';
import { MESSAGE } from '@client/constants/message';

const defaultTip = MESSAGE.LOADING.DEFAULT;

const Loading = ({ fullscreen, tip, tipComponent }) => {
  const className = classNames({
    [styles.loading__wrapper]: true,
    [styles.fullscreen]: fullscreen,
  });

  useEffect(() => {
    if (fullscreen) {
      const target = document.createElement('div');
      target.className = className;
      document.body.appendChild(target);
      if (target) {
        const LoadingComponent = (
          <>
            <Spin size='large' />
            <span className={styles.tip}>{tip || defaultTip}</span>
          </>
        );
        ReactDOM.render(LoadingComponent, target);
        return () => {
          ReactDOM.unmountComponentAtNode(target);
          document.body.removeChild(target);
        };
      }
    }
  }, [tip, className, fullscreen]);

  if (!fullscreen) {
    return (
      <div className={className}>
        <Spin size='large' />
        <span className={styles.tip}>{tipComponent || tip || defaultTip}</span>
      </div>
    );
  } else {
    return null;
  }
};

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  tip: PropTypes.string,
  tipComponent: PropTypes.object,
};

export default Loading;
