import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const FORM_ANSWER_ENUMS = {
  STATUS: {
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    RESET: 'RESET',
  },
};

const FORM_ANSWER_FIELDS = {
  ...COMMON_FIELDS,
  ANSWER: 'answer',
  CREATOR: 'creator',
  INSTITUTION: 'institution',
  ELEMENT_1_COMPLETION_STATUS: 'element1CompletionStatus',
  ELEMENT_2_COMPLETION_STATUS: 'element2CompletionStatus',
  ELEMENT_3_COMPLETION_STATUS: 'element3CompletionStatus',
  ELEMENT_4_COMPLETION_STATUS: 'element4CompletionStatus',
  COHORT: 'cohort',
  FORM: 'form',
  BASIC_PUNCTUALITY: 'basicPunctuality',
  EXPANDING_PUNCTUALITY: 'expandingPunctuality',
  SCORES: 'scores',
  STATUS: 'status',
  PICKED_QUESTION_NAMES: 'pickedQuestionNames',
  ELEMENT_4_EXPIRES_AT: 'element4ExpiresAt',
  BASIC_SUBMITTED: 'basicSubmitted',
  EXPANDING_SUBMITTED: 'expandingSubmitted',
  IS_MARKED_ALL_BASIC: 'isMarkedAllBasic',
  IS_MARKED_ALL_EXPANDING: 'isMarkedAllExpanding',
  FILE_UUID: 'fileUUID',
};

const FORM_ANSWER_SCORES_FIELDS = {
  QUESTION_ID: 'questionId',
  RUBRIC_ID: 'rubricId',
  SCORE: 'score',
};

export { FORM_ANSWER_FIELDS, FORM_ANSWER_ENUMS, FORM_ANSWER_SCORES_FIELDS };
