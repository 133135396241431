import { USER_ENUMS } from '@client/constants/db-fields';

const PERMISSION_TYPES = {
  // Institution
  CAN_MANAGE_INSTITUTIONS: 'CAN_MANAGE_INSTITUTIONS',
  CAN_EDIT_INSTITUTIONS: 'CAN_EDIT_INSTITUTIONS',
  CAN_DELETE_INSTITUTIONS: 'CAN_DELETE_INSTITUTIONS',
  // Cohort
  CAN_MANAGE_COHORTS: 'CAN_MANAGE_COHORTS',
  // User
  CAN_VIEW_USERS: 'CAN_VIEW_USERS',
  CAN_EDIT_OTHER_USERS: 'CAN_EDIT_OTHER_USERS',
  // Assessment
  CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS: 'CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS',
  CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS: 'CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS',
  CAN_VIEW_ASSESSMENTS: 'CAN_VIEW_ASSESSMENTS',
  CAN_DOWNLOAD_ASSESSMENTS: 'CAN_DOWNLOAD_ASSESSMENTS',
  CAN_RESET_ASSESSMENTS: 'CAN_RESET_ASSESSMENTS',
  CAN_DELETE_ASSESSMENTS: 'CAN_DELETE_ASSESSMENTS',
  CAN_START_ASSESSMENTS: 'CAN_START_ASSESSMENTS',
  CAN_MARK_ASSESSMENTS: 'CAN_MARK_ASSESSMENTS',
  CAN_EDIT_ASSESSMENTS: 'CAN_EDIT_ASSESSMENTS',
};

const PERMISSIONS = {
  [USER_ENUMS.ROLE.ADMIN]: {
    [PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS]: true,
    [PERMISSION_TYPES.CAN_VIEW_USERS]: true,
    [PERMISSION_TYPES.CAN_EDIT_OTHER_USERS]: true,
    // Institution
    [PERMISSION_TYPES.CAN_EDIT_INSTITUTIONS]: true,
    [PERMISSION_TYPES.CAN_DELETE_INSTITUTIONS]: true,
    // Cohort
    [PERMISSION_TYPES.CAN_MANAGE_COHORTS]: true,
    // Assessment
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DOWNLOAD_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DELETE_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_START_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_EDIT_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_RESET_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_MARK_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS]: true,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS]: true,
  },
  [USER_ENUMS.ROLE.INSTITUTION_ADMIN]: {
    [PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS]: true,
    [PERMISSION_TYPES.CAN_VIEW_USERS]: true,
    [PERMISSION_TYPES.CAN_EDIT_OTHER_USERS]: true,
    // Institution
    [PERMISSION_TYPES.CAN_EDIT_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_DELETE_INSTITUTIONS]: false,
    // Cohort
    [PERMISSION_TYPES.CAN_MANAGE_COHORTS]: true,
    // Assessment
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DOWNLOAD_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DELETE_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_START_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_EDIT_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_RESET_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_MARK_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS]: true,
  },
  [USER_ENUMS.ROLE.ASSESSOR]: {
    [PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_VIEW_USERS]: false,
    [PERMISSION_TYPES.CAN_EDIT_OTHER_USERS]: false,
    // Institution
    [PERMISSION_TYPES.CAN_EDIT_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_DELETE_INSTITUTIONS]: false,
    // Cohort
    [PERMISSION_TYPES.CAN_MANAGE_COHORTS]: false,
    // Assessment
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DOWNLOAD_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DELETE_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_START_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_EDIT_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_MARK_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_RESET_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS]: true,
  },
  [USER_ENUMS.ROLE.STUDENT]: {
    [PERMISSION_TYPES.CAN_MANAGE_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_VIEW_USERS]: false,
    [PERMISSION_TYPES.CAN_EDIT_OTHER_USERS]: false,
    // Institution
    [PERMISSION_TYPES.CAN_EDIT_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_DELETE_INSTITUTIONS]: false,
    // Cohort
    [PERMISSION_TYPES.CAN_MANAGE_COHORTS]: false,
    // Assessment
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_DOWNLOAD_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_DELETE_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_START_ASSESSMENTS]: true,
    [PERMISSION_TYPES.CAN_EDIT_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_MARK_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_RESET_ASSESSMENTS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_INSTITUTIONS]: false,
    [PERMISSION_TYPES.CAN_VIEW_ASSESSMENTS_IN_ALL_COHORTS]: false,
  },
};

export { PERMISSIONS, PERMISSION_TYPES };
