import React from 'react';
import { ReactComponent as UniLogo } from '@client/static/uni-logo.svg';
import AfgtLogoLarge from '@client/static/logo-large.png';

const UniversityLogo = () => {
  return <UniLogo />;
};

const AppLogo = () => {
  return <img src={AfgtLogoLarge} alt={'logo small'} width={200} />;
};

const AppLogoLarge = () => {
  return <img src={AfgtLogoLarge} alt={'logo large'} width={200} />;
};

export { UniversityLogo, AppLogo, AppLogoLarge };
