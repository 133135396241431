import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const COHORT_FIELDS = {
  ...COMMON_FIELDS,
  NAME: 'name',
  INSTITUTION: 'institution',
  ARCHIVED: 'archived',
  YEAR: 'year',
  FORM: 'form',
};

export { COHORT_FIELDS };
