const ROOT_ROUTES = {
  ALL: '*',
  LOG_IN: '/login',
  HOME: '/',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  COHORTS: '/cohorts',
  USERS: '/users',
  USER: '/users/:userId',
  ASSESSMENTS: '/assessments',
  INSTITUTIONS: '/institutions',
  EMAIL_VERIFICATION: '/email-verification',
};

const ASSESSMENTS_ROUTES = {
  EXIST_FORM_ANSWERS: `${ROOT_ROUTES.ASSESSMENTS}`,
  NEW_FORM: `${ROOT_ROUTES.ASSESSMENTS}/:formId/new`,
  EXIST_FORM_ANSWER: `${ROOT_ROUTES.ASSESSMENTS}/form/:formId/assessments/:formAnswerId`,
};

export { ROOT_ROUTES, ASSESSMENTS_ROUTES };
