import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import {
  createFormAnswer,
  deleteFormAnswer,
  downloadAnswerAsHtml,
  downloadAnswerAsPdf,
  getForm,
  getFormAnswer,
  getFormAnswers,
  getForms,
  getPrefilledFormData,
  lockFormAnswer,
  markFormAnswer,
  resetFormAnswer,
  unlockFormAnswer,
  updateFormAnswer,
  updateFormAnswerMarkingStatus,
  updateFormAnswerStatus,
} from '@client/store/actions/form';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';
import { findIndex } from 'lodash';
import { FORM_ANSWER_FIELDS } from '@client/constants/db-fields/formAnswers';

const {
  FORM: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.FORMS]: [],
  [FIELDS.FORM]: null,
  [FIELDS.FORM_ANSWER]: null,
  [FIELDS.FORM_ANSWERS]: [],
  [FIELDS.PREFILLED_DATA]: null,
  [FIELDS.PDF_DATA]: null,
  [FIELDS.IS_GETTING_FORM]: false,
  [FIELDS.IS_UPDATING_FORM]: false,
  [FIELDS.IS_MARKING_FORM_ANSWER]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER]: false,
  [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
  [FIELDS.IS_CREATING_FORM_ANSWER]: false,
  [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
  [FIELDS.IS_DELETING_FORM_ANSWER]: false,
  [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
  [FIELDS.IS_LOCKING]: false,
  [FIELDS.IS_UNLOCKING]: false,
  [FIELDS.IS_DOWNLOADING]: false,
  [FIELDS.IS_DOWNLOADING_HTML]: false,
  [FIELDS.FORCE_SWITCH_URL]: false, // hide url change alert
  [FIELDS.IS_RESETTING_FORM_ANSWER]: false,
};

const reducers = {
  clearFormAnswer: state => {
    state[FIELDS.FORM_ANSWER] = null;
  },
  setForceSwitchUrl: (state, action) => {
    state[FIELDS.FORCE_SWITCH_URL] = action.payload;
  },
};

const extraReducers = {
  [getForm.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.FORM_ANSWER]: null,
    [FIELDS.FORM]: null,
  }),
  [getForm.fulfilled]: (state, action) => {
    if (action.payload.justCreatedFormAnswer) {
      return {
        ...state,
        [FIELDS.IS_GETTING_FORM]: false,
        [FIELDS.FORM]: action.payload.form,
        [FIELDS.FORM_ANSWER]: action.payload.justCreatedFormAnswer,
      };
    } else {
      return {
        ...state,
        [FIELDS.IS_GETTING_FORM]: false,
        [FIELDS.FORM]: action.payload.form,
      };
    }
  },
  [getForm.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [getForms.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.FORMS]: [],
  }),
  [getForms.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.FORMS]: action.payload,
  }),
  [getForms.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [getFormAnswers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
  }),
  [getFormAnswers.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWERS]: action.payload,
  }),
  [getFormAnswers.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [getFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
  }),
  [getFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [getFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_FORM_ANSWER]: true,
  }),
  [createFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [createFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: true,
  }),
  [updateFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [updateFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateFormAnswerStatus.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: true,
  }),
  [updateFormAnswerStatus.fulfilled]: (state, action) => {
    const current = state[FIELDS.FORM_ANSWERS]?.content;
    const index = findIndex(current, { [FORM_ANSWER_FIELDS.ID]: action.payload?.[FORM_ANSWER_FIELDS.ID] });
    let newAnswers = current;
    if (index !== -1) {
      newAnswers = [...(current?.slice(0, index) || []), action.payload, ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
      [FIELDS.FORM_ANSWERS]: { ...state[FIELDS.FORM_ANSWERS], content: newAnswers },
    };
  },
  [updateFormAnswerStatus.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [resetFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_RESETTING_FORM_ANSWER]: true,
  }),
  [resetFormAnswer.fulfilled]: (state, action) => {
    const current = state[FIELDS.FORM_ANSWERS]?.content;
    const index = findIndex(current, { [FORM_ANSWER_FIELDS.ID]: action.payload?.resetAssessment?.[FORM_ANSWER_FIELDS.ID] });
    let newAnswers = current;
    if (index !== -1) {
      newAnswers = [
        ...(current?.slice(0, index) || []),
        action.payload?.resetAssessment,
        action.payload?.newAssessment,
        ...(current?.slice(index + 1) || []),
      ];
    }
    return {
      ...state,
      [FIELDS.IS_RESETTING_FORM_ANSWER]: false,
      [FIELDS.FORM_ANSWERS]: { ...state[FIELDS.FORM_ANSWERS], content: newAnswers },
    };
  },
  [resetFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_RESETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [updateFormAnswerMarkingStatus.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: true,
  }),
  [updateFormAnswerMarkingStatus.fulfilled]: (state, action) => {
    const current = state[FIELDS.FORM_ANSWERS]?.content;
    const index = findIndex(current, { [FORM_ANSWER_FIELDS.ID]: action.payload?.[FORM_ANSWER_FIELDS.ID] });
    let newAnswers = current;
    if (index !== -1) {
      newAnswers = [...(current?.slice(0, index) || []), action.payload, ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
      [FIELDS.FORM_ANSWERS]: { ...state[FIELDS.FORM_ANSWERS], content: newAnswers },
      [FIELDS.FORM_ANSWER]: action.payload,
    };
  },
  [updateFormAnswerMarkingStatus.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [deleteFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING_FORM_ANSWER]: true,
  }),
  [deleteFormAnswer.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.FORM_ANSWERS]?.content;
    const deletedId = action.payload;
    const newAnswers = existed?.filter(each => each?.[COMMON_FIELDS.ID] !== deletedId);
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING_FORM_ANSWER]: false,
      [FIELDS.FORM_ANSWERS]: { content: newAnswers, total: state?.[FIELDS.FORM_ANSWERS].total - 1 },
    };
  },
  [deleteFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [getPrefilledFormData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.PREFILLED_DATA]: null,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: true,
  }),
  [getPrefilledFormData.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
    [FIELDS.PREFILLED_DATA]: action.payload,
  }),
  [getPrefilledFormData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [downloadAnswerAsPdf.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.PDF_DATA]: null,
    [FIELDS.IS_DOWNLOADING]: true,
  }),
  [downloadAnswerAsPdf.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.PDF_DATA]: action.payload,
    [FIELDS.IS_DOWNLOADING]: false,
  }),
  [downloadAnswerAsPdf.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DOWNLOADING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [downloadAnswerAsHtml.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.HTML_DATA]: null,
    [FIELDS.IS_DOWNLOADING_HTML]: true,
  }),
  [downloadAnswerAsHtml.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.HTML_DATA]: action.payload,
    [FIELDS.IS_DOWNLOADING_HTML]: false,
  }),
  [downloadAnswerAsHtml.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DOWNLOADING_HTML]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [lockFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOCKING]: true,
  }),
  [lockFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.FORM_ANSWER]: action.payload,
    [FIELDS.IS_LOCKING]: false,
  }),
  [lockFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_LOCKING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [unlockFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UNLOCKING]: true,
  }),
  [unlockFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.FORM_ANSWER]: action.payload,
    [FIELDS.IS_UNLOCKING]: false,
  }),
  [unlockFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UNLOCKING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [markFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_MARKING_FORM_ANSWER]: true,
  }),
  [markFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.FORM_ANSWER]: action.payload,
    [FIELDS.IS_MARKING_FORM_ANSWER]: false,
  }),
  [markFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_MARKING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const formSlice = createSlice({
  name: REDUCER_STATE.FORM.NAME,
  initialState,
  reducers,
  extraReducers,
});

const { clearFormAnswer, setForceSwitchUrl } = formSlice.actions;

export { formSlice, clearFormAnswer, setForceSwitchUrl };
