import styled from 'styled-components';
import background from '@client/static/bg.svg';
import { Breadcrumb } from 'antd';

/**
 * Global wrapper for whole webpage
 */
const GlobalWrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-image: url(${background});
  background-size: auto 100%;
`;

// /**
//  * Route path wrapper
//  */
// const RoutePathWrapper = styled(Breadcrumb)`
//   font-size: 18px;
//   .ant-breadcrumb-link {
//     color: black;
//     a {
//       color: black !important;
//     }
//   }
//   .ant-breadcrumb-separator {
//     color: black;
//   }
// `;

/**
 * Support hover in wrapper
 */
const CardWrapper = styled.div`
  background-color: rgb(255, 255, 255, 0.7);
  width: fit-content;
  border-radius: 15px;
  border: 1px solid lightgray;
  transition-property: box-shadow, border-color;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  &:hover {
    cursor: default;
    box-shadow: inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.5), 0 3px 8px 0 #555a64;
    border-color: lightgray;
  }
`;

/**
 * Display content in center
 */
const CenterWrapper = styled.div`
  display: flex;
  height: ${props => (props.height ? props.height : 'default')};
  flex-direction: ${props => (props.isHorizontal ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  gap: ${props => (props.size ? props.size + 'px' : 'default')};
  margin: ${props => (props.margin ? props.margin + 'px' : 'default')};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/**
 * Text - alert
 */
const AlertTextWrapper = styled.div`
  color: red;
  font-style: italic;
  margin-top: 10px;
`;

/**
 * Route path breadcrumb wrapper
 */
const RoutePathWrapper = styled(Breadcrumb)`
  font-size: 18px;
  .ant-breadcrumb-link {
    color: black;
    a {
      color: black !important;
    }
  }
  .ant-breadcrumb-separator {
    color: black;
  }
`;

/**
 * List of card wrapper, like institution page
 */
const CardListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto;
  flex-wrap: wrap;
  padding: 0 10px 10px 0;
  max-height: calc(100% - 30px);
  gap: 15px;
  row-gap: 15px;
  overflow-y: scroll;
`;

/**
 * Editable card, like institution page
 */
const EditableCard = styled(CardWrapper)`
  width: 300px;
  padding: 0 10px;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')} !important;
  display: flex;
  flex-direction: column;
  //height: 270px;
  min-height: 230px;
  max-height: 350px;
  justify-content: center;
  border: ${props => (props.isActive ? '5px solid lightseagreen' : 'default')};
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: end;
      color: grey;
      font-style: italic;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .action {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .sub_title {
    color: grey;
    font-style: italic;
    font-weight: bold;
    font-size: 15px;
  }
  .description {
    font-style: italic;
    color: grey;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    color: lightgrey;
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
  }
  .footer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  // Plus container
  .plus_icon_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 230px;
    max-height: 350px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
`;

export { GlobalWrapper, CardWrapper, CardListWrapper, CenterWrapper, IconWrapper, AlertTextWrapper, RoutePathWrapper, EditableCard };
