import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRouteWrapper = ({ component: Component, ...otherProps }) => {
  return <Route {...otherProps} render={props => <Component {...props} />} />;
};

PublicRouteWrapper.propTypes = {
  component: PropTypes.object.isRequired,
};
export default PublicRouteWrapper;
