import { createAsyncThunk } from '@reduxjs/toolkit';
import { formApi } from '@client/services/api';
import {
  CREATE_FORM_ANSWER,
  DELETE_FORM_ANSWER,
  DOWNLOAD_FORM_ANSWER_DATA_AS_HTML,
  DOWNLOAD_FORM_ANSWER_DATA_AS_PDF,
  GET_FORM,
  GET_FORM_ANSWER,
  GET_FORM_ANSWERS,
  GET_FORMS,
  GET_PREFILLED_FORM_DATA,
  LOCK_FORM_ANSWER,
  MARK_FORM_ANSWER,
  RESET_FORM_ANSWER,
  UNLOCK_FORM_ANSWER,
  UPDATE_FORM_ANSWER,
  UPDATE_FORM_ANSWER_MARKING_STATUS,
  UPDATE_FORM_ANSWER_STATUS,
} from '@client/store/types/form';

/**
 * Get all form templates
 */
const getForms = createAsyncThunk(GET_FORMS, async () => {
  return await formApi.getForms();
});

/**
 * Get form template
 */
const getForm = createAsyncThunk(GET_FORM, async ({ formId, formAnswerId, isFetchFullExpanding }) => {
  return await formApi.getForm({ formId, formAnswerId, isFetchFullExpanding });
});

/**
 * Get all answers for this template
 */
const getFormAnswers = createAsyncThunk(GET_FORM_ANSWERS, async params => {
  return await formApi.getFormAnswers(params);
});

/**
 * Get one answer
 */
const getFormAnswer = createAsyncThunk(GET_FORM_ANSWER, async payload => {
  return await formApi.getFormAnswer(payload);
});

/**
 * Create new form answer
 */
const createFormAnswer = createAsyncThunk(
  CREATE_FORM_ANSWER,
  async ({
    formId,
    answer,
    isSubmitBasic,
    isSubmitExpanding,
    element1CompletionStatus,
    element2CompletionStatus,
    element3CompletionStatus,
    fileUUID,
  }) => {
    return await formApi.createFormAnswer({
      formId,
      answer,
      isSubmitBasic,
      isSubmitExpanding,
      element1CompletionStatus,
      element2CompletionStatus,
      element3CompletionStatus,
      fileUUID,
    });
  },
);

/**
 * Update form answer
 * @type {AsyncThunk<*, void, {}>}
 */
const updateFormAnswer = createAsyncThunk(
  UPDATE_FORM_ANSWER,
  async ({
    formAnswerId,
    answer,
    isSubmitBasic,
    isSubmitExpanding,
    element1CompletionStatus,
    element2CompletionStatus,
    element3CompletionStatus,
    element4CompletionStatus,
    fileUUID,
  }) => {
    return await formApi.updateFormAnswer({
      formAnswerId,
      answer,
      isSubmitBasic,
      isSubmitExpanding,
      element1CompletionStatus,
      element2CompletionStatus,
      element3CompletionStatus,
      element4CompletionStatus,
      fileUUID,
    });
  },
);

/**
 * Update form answer status
 * @type {AsyncThunk<*, void, {}>}
 */
const updateFormAnswerStatus = createAsyncThunk(UPDATE_FORM_ANSWER_STATUS, async payload => {
  return await formApi.updateFormAnswerStatus(payload);
});

/**
 * Update form answer marking status
 * @type {AsyncThunk<*, void, {}>}
 */
const updateFormAnswerMarkingStatus = createAsyncThunk(UPDATE_FORM_ANSWER_MARKING_STATUS, async payload => {
  return await formApi.updateFormAnswerMarkingStatus(payload);
});

/**
 * Get prefill data
 * @type {AsyncThunk<*, void, {}>}
 */
const getPrefilledFormData = createAsyncThunk(GET_PREFILLED_FORM_DATA, async payload => {
  return await formApi.getPrefilledFormData(payload);
});

const downloadAnswerAsPdf = createAsyncThunk(DOWNLOAD_FORM_ANSWER_DATA_AS_PDF, async payload => {
  return await formApi.downloadPdf(payload);
});

const downloadAnswerAsHtml = createAsyncThunk(DOWNLOAD_FORM_ANSWER_DATA_AS_HTML, async ({ formId, formAnswerId }) => {
  return await formApi.downloadFormAsHtml({ formId, formAnswerId });
});

const markFormAnswer = createAsyncThunk(MARK_FORM_ANSWER, async ({ formAnswerId, score }) => {
  return await formApi.markFormAnswer({ formAnswerId, score });
});

const resetFormAnswer = createAsyncThunk(RESET_FORM_ANSWER, async ({ formAnswerId, mode }) => {
  return await formApi.resetFormAnswer({ formAnswerId, mode });
});

const lockFormAnswer = createAsyncThunk(LOCK_FORM_ANSWER, async ({ formId, formAnswerId }) => {
  return await formApi.lockFormAnswer({ formId, formAnswerId });
});

const unlockFormAnswer = createAsyncThunk(UNLOCK_FORM_ANSWER, async ({ formId, formAnswerId }) => {
  return await formApi.unlockFormAnswer({ formId, formAnswerId });
});

const deleteFormAnswer = createAsyncThunk(DELETE_FORM_ANSWER, async formAnswerId => {
  await formApi.deleteFormAnswer(formAnswerId);
  return formAnswerId;
});

export {
  lockFormAnswer,
  unlockFormAnswer,
  getForm,
  getForms,
  getFormAnswers,
  getFormAnswer,
  deleteFormAnswer,
  createFormAnswer,
  updateFormAnswer,
  updateFormAnswerStatus,
  updateFormAnswerMarkingStatus,
  getPrefilledFormData,
  downloadAnswerAsPdf,
  downloadAnswerAsHtml,
  markFormAnswer,
  resetFormAnswer,
};
