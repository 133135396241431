import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const USER_FIELDS = {
  ...COMMON_FIELDS,
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PASSWORD: 'password',
  ROLE: 'role',
  STUDENT_ID: 'studentId',
  INSTITUTION: 'institution',
  COHORT: 'cohort',
  IS_SUSPENDED: 'isSuspended',
  BASIC_OPENING_START: 'basicOpeningStart',
  BASIC_OPENING_END: 'basicOpeningEnd',
  EXPANDING_OPENING_START: 'expandingOpeningStart',
  EXPANDING_OPENING_END: 'expandingOpeningEnd',
};

const USER_ENUMS = {
  ROLE: {
    ADMIN: 'admin',
    INSTITUTION_ADMIN: 'institutionAdmin',
    STUDENT: 'student',
    ASSESSOR: 'assessor',
  },
};

export { USER_FIELDS, USER_ENUMS };
