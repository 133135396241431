import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_USER,
  CREATE_USERS,
  DELETE_USER,
  GET_USER,
  GET_USERS,
  GROUP_UPDATE_USERS,
  RESET_USER_PASSWORD,
  UPDATE_USER,
} from '@client/store/types/user';
import { userApi } from '@client/services/api';

/**
 * Get users
 */
const getUsers = createAsyncThunk(GET_USERS, async params => {
  return await userApi.getUsers(params);
});

/**
 * Get user
 */
const getUser = createAsyncThunk(GET_USER, async userId => {
  return await userApi.getUser(userId);
});

/**
 * Create user
 */
const createUser = createAsyncThunk(CREATE_USER, async payload => {
  return await userApi.createUser(payload);
});

/**
 * Create users
 */
const createUsers = createAsyncThunk(CREATE_USERS, async payload => {
  return await userApi.createUsers(payload);
});

/**
 * Update user
 */
const updateUser = createAsyncThunk(UPDATE_USER, async ({ userId, body }) => {
  return await userApi.updateUser({ userId, body });
});

/**
 * Group update users
 */
const groupUpdateUsers = createAsyncThunk(GROUP_UPDATE_USERS, async ({ cohortId, userIds, values }) => {
  return await userApi.groupUpdateUsers({ cohortId, userIds, values });
});

/**
 * Reset user password
 */
const resetUserPassword = createAsyncThunk(RESET_USER_PASSWORD, async ({ id, token, password }) => {
  return await userApi.resetUserPassword({ id, token, password });
});

/**
 * Delete user
 */
const deleteUser = createAsyncThunk(DELETE_USER, async payload => {
  await userApi.deleteUser(payload);
  return payload;
});

export { getUsers, getUser, createUsers, createUser, updateUser, deleteUser, groupUpdateUsers, resetUserPassword };
