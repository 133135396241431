import '@client/styles/index.less';
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { history, store } from '@client/store';
import RootRoutes from '@client/routes/root';
import { ConfigProvider } from 'antd';

const App = () => {
  return (
    <Provider store={store()}>
      <ConnectedRouter history={history}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#094183',
              colorPrimaryLight: '#4888d0',
              lineHeight: 1.57,
            },
          }}
        >
          <RootRoutes />
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
