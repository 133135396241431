import { createAsyncThunk } from '@reduxjs/toolkit';
import { institutionApi } from '@client/services/api';
import {
  ARCHIVE_INSTITUTION,
  CREATE_INSTITUTION,
  DELETE_INSTITUTION,
  GET_INSTITUTION,
  GET_INSTITUTIONS,
  UN_ARCHIVE_INSTITUTION,
  UPDATE_INSTITUTION,
} from '@client/store/types/institution';

/**
 * Get institutions
 */
const getInstitutions = createAsyncThunk(GET_INSTITUTIONS, async params => {
  return await institutionApi.getInstitutions(params);
});

/**
 * Get institution
 */
const getInstitution = createAsyncThunk(GET_INSTITUTION, async id => {
  return await institutionApi.getInstitution(id);
});

/**
 * Create institution
 */
const createInstitution = createAsyncThunk(CREATE_INSTITUTION, async payload => {
  return await institutionApi.createInstitution(payload);
});

/**
 * Update institution
 */
const updateInstitution = createAsyncThunk(UPDATE_INSTITUTION, async ({ id, body }) => {
  return await institutionApi.updateInstitution({ id, body });
});

/**
 * Archive institution
 */
const archiveInstitution = createAsyncThunk(ARCHIVE_INSTITUTION, async id => {
  return await institutionApi.archiveInstitution(id);
});

/**
 * Un-archive institution
 */
const unArchiveInstitution = createAsyncThunk(UN_ARCHIVE_INSTITUTION, async id => {
  return await institutionApi.unArchiveInstitution(id);
});

/**
 * Delete institution
 */
const deleteInstitution = createAsyncThunk(DELETE_INSTITUTION, async payload => {
  await institutionApi.deleteInstitution(payload);
  return payload;
});

export { getInstitutions, getInstitution, createInstitution, updateInstitution, deleteInstitution, archiveInstitution, unArchiveInstitution };
