import BaseApi from './base';

class InstitutionApi extends BaseApi {
  /**
   * Get list of institution
   * @return {Promise<*>}
   */
  async getInstitutions(params) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.INSTITUTIONS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get institution
   * @return {Promise<*>}
   */
  async getInstitution(id) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.INSTITUTION({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create Institution
   * @return {Promise<*>}
   */
  async createInstitution(payload) {
    const _self = this;
    try {
      const body = payload;
      const res = await _self.client.post(_self.endpoints.INSTITUTIONS, body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update Institution
   * @return {Promise<*>}
   */
  async updateInstitution({ id, body }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.INSTITUTION({ id }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Archive Institution
   * @return {Promise<*>}
   */
  async archiveInstitution(id) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.INSTITUTION_ARCHIVE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * unArchive Institution
   * @return {Promise<*>}
   */
  async unArchiveInstitution(id) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.INSTITUTION_UN_ARCHIVE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete Institution
   * @return {Promise<*>}
   */
  async deleteInstitution(id) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.INSTITUTION({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { InstitutionApi };
