const ENDPOINTS = {
  // Auth
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  CURRENT: '/auth/current',
  // Institution
  INSTITUTIONS: '/institutions',
  INSTITUTION: ({ id }) => `/institutions/${id}`,
  INSTITUTION_ARCHIVE: ({ id }) => `/institutions/${id}/archive`,
  INSTITUTION_UN_ARCHIVE: ({ id }) => `/institutions/${id}/un-archive`,
  // Cohort
  COHORTS: '/cohorts',
  COHORT: ({ id }) => `/cohorts/${id}`,
  COHORT_ARCHIVE: ({ id }) => `/cohorts/${id}/archive`,
  COHORT_UN_ARCHIVE: ({ id }) => `/cohorts/${id}/un-archive`,
  // User
  USERS: '/users',
  USER: ({ userId }) => `/users/${userId}`,
  RESET_USER_PASSWORD: '/users/password/reset-password',
  // Form
  FORMS: '/forms',
  FORM: ({ formId }) => `/forms/${formId}`,
  FORM_ANSWERS: `/form-answers`,
  FORM_ANSWERS_IN_POST: ({ formId }) => `/forms/${formId}/form-answers`,
  FORM_ANSWER: ({ formAnswerId }) => `/form-answers/${formAnswerId}`,
  FORM_ANSWER_STATUS: ({ formAnswerId }) => `/form-answers/${formAnswerId}/status`,
  FORM_ANSWER_MARKING_STATUS: ({ formAnswerId }) => `/form-answers/${formAnswerId}/markingStatus`,
  FORM_ANSWER_MARK: ({ formAnswerId }) => `/form-answers/${formAnswerId}/mark`,
  FORM_ANSWER_RESET: ({ formAnswerId }) => `/form-answers/${formAnswerId}/reset`,
  FORM_ANSWER_LOCK: formAnswerId => `/form-answers/${formAnswerId}/lock`,
  FORM_ANSWER_UNLOCK: formAnswerId => `/form-answers/${formAnswerId}/unlock`,
  FORM_INIT_ANSWER: ({ formId }) => `/forms/${formId}/caps`,
  FORM_ANSWER_PDF: ({ formAnswerId }) => `/form-answers/${formAnswerId}/pdf`,
  FORM_ANSWER_HTML: ({ formAnswerId }) => `/form-answers/${formAnswerId}/html`,
  FILES: '/files',
  FILE: ({ id }) => `/files/${id}`,
  // General
  GENERAL_GIT_VERSION: '/general/version',
  GENERAL_MARKING_DATA: '/general/marking-data',
  NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA: '/general/number-of-assessments-by-status-data',
  GENERAL_COMPLETION_DATA: '/general/completion-data',
  // Token
  VERIFY_TOKEN: '/tokens/validate',
  // Email
  SEND_RESET_PASSWORD_EMAIL: '/emails/send-reset-password-email',
};

export default ENDPOINTS;
