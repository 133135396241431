import BaseApi from './base';

class GeneralApi extends BaseApi {
  /**
   * Get git version
   * @return {Promise<*>}
   */
  async getGitVersion() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.GENERAL_GIT_VERSION);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get marking data
   * @return {Promise<*>}
   */
  async getMarkingData() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.GENERAL_MARKING_DATA);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get number of assessment by status data
   * @return {Promise<*>}
   */
  async getNumberOfAssessmentsByStatusData() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get completion data
   * @return {Promise<*>}
   */
  async getCompletionData() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.GENERAL_COMPLETION_DATA);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { GeneralApi };
