import { USER_ENUMS } from '@client/constants/db-fields';
import { FORM_ANSWER_ENUMS } from '@client/constants/db-fields/formAnswers';

const DEVELOPED_BY = 'Developed by Melbourne eResearch Group 2022';
const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const USER_ROLE_TAG_COLOR = {
  [USER_ENUMS.ROLE.ADMIN]: 'red',
  [USER_ENUMS.ROLE.INSTITUTION_ADMIN]: 'pink',
  [USER_ENUMS.ROLE.STUDENT]: 'yellow',
  [USER_ENUMS.ROLE.ASSESSOR]: 'blue',
};

const ASSESSMENT_STATUS_TAG_COLOR = {
  [FORM_ANSWER_ENUMS.STATUS.STARTED]: 'red',
  [FORM_ANSWER_ENUMS.STATUS.RESET]: 'orange',
  [FORM_ANSWER_ENUMS.STATUS.COMPLETED]: 'green',
};

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_SEARCH_PARAMS = {
  INSTITUTION: 'institution',
  COHORT: 'cohort',
  PAGE: 'page',
  TOKEN: 'token',
};

const DEFAULT_TABLE_QUERY = {
  skip: 0,
  limit: 50,
};

export {
  DEVELOPED_BY,
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  USER_ROLE_TAG_COLOR,
  EMAIL_REGEX,
  ASSESSMENT_STATUS_TAG_COLOR,
  URL_SEARCH_PARAMS,
  DEFAULT_TABLE_QUERY,
};
