import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { ROOT_ROUTES } from '@client/constants/routes';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { REDUCER_STATE } from '@client/constants/store';
import { USER_ENUMS, USER_FIELDS } from '@client/constants/db-fields';
import { MESSAGE } from '@client/constants/message';
import { Spin } from 'antd';

const LoginRouteWrapper = ({ component: Component, ...otherProps }) => {
  const location = useLocation();
  const currentUser = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.DATA])?.user;
  const isLoggingIn = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGING_IN]);
  const isLoggedIn = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]);
  const whichPageAfterLoggedin = currentUser?.[USER_FIELDS.ROLE] === USER_ENUMS.ROLE.ADMIN ? ROOT_ROUTES.HOME : ROOT_ROUTES.ASSESSMENTS;
  const redirectedFrom = location?.state?.redirectedFrom || whichPageAfterLoggedin;

  return (
    <Spin spinning={isLoggingIn} tip={MESSAGE.LOADING.LOGGING}>
      {!isLoggedIn ? (
        <Route {...otherProps} render={props => <Component {...props} />} />
      ) : (
        <Route {...otherProps} render={props => <Redirect to={redirectedFrom} {...props} />} />
      )}
    </Spin>
  );
};

LoginRouteWrapper.propTypes = {
  component: PropTypes.object.isRequired,
};
export default LoginRouteWrapper;
