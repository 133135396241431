import BaseApi from './base';

class FileApi extends BaseApi {
  async createFile(fileFormData) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.FILES, fileFormData, { headers: { 'content-type': 'multipart/form-data' } });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async downloadFile(id) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FILE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async deleteFile(id) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.FILE({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export default FileApi;
