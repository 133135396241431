import axios from 'axios';
import { localforageClient } from '@client/services/localforage';
import { LOCAL_FORAGE_ITEM_KEY } from '@client/constants/localforage';

const baseURL = process.env.REACT_APP_API_PATH || '/api';

const client = axios.create({
  baseURL,
});

client.interceptors.request.use(async config => {
  const token = await localforageClient.getItem(LOCAL_FORAGE_ITEM_KEY.SESSION_TOKEN);
  config.headers.common['Authorization'] = `${token}`;
  // config.headers['Authorization'] = `${token}`;
  return config;
});

export default client;
