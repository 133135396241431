import styled from 'styled-components';
import { Layout } from 'antd';
import { CardWrapper } from '@client/styles/wrapper';
import wallpaper from '@client/static/bg-web.jpg';

/**
 * Whole layout wrapper
 */
const AuthLayout = styled(Layout)`
  height: 100%;
  background-image: url(${wallpaper});
  background-repeat: no-repeat;
  background-size: cover;
`;

/**
 * Content wrapper
 */
const AuthContentLayout = styled(Layout)`
  width: 100vw;
  height: 100vh;
  background-color: white;
`;

/**
 * Header
 */
const AuthLayoutHeader = styled(Layout.Header)`
  align-items: center;
  background-color: #dddddd !important;
  height: 48px;
  color: black;
  display: flex;
  padding: 0 20px 0 20px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  // Icon
  .anticon {
    font-size: 20px;
    color: #666666;
  }

  .tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    > * {
      margin: 0 10px;
    }
  }
`;

/**
 * Sidebar
 */
const AuthLayoutSider = styled(Layout.Sider)`
  background-color: white !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  justify-content: space-around;
  overflow: hidden;
  background-image: url(${wallpaper});
  background-repeat: no-repeat;
  background-size: cover;
  // logout and setting
  .tool-container {
    margin-bottom: 25px;
    color: white;
  }

  .ant-layout-sider-trigger {
    background-color: var(--primary-color-light) !important;
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const AuthLayoutCornerHeader = styled.div`
  color: transparent !important;
  padding-bottom: 10px;
`;

const AuthLayoutSideFooter = styled.div`
  color: white;
  line-height: 18px;
  width: 100%;

  > div {
    margin-top: 8px;
  }

  .logo {
    width: 50%;
    margin: 0 !important;
  }
`;

/**
 * Content wrapper
 */
const AuthLayoutContent = styled(Layout.Content)`
  background-color: transparent !important;
  height: 100%;
  padding: 10px;
  background-image: url(${wallpaper});
  background-repeat: no-repeat;
  background-size: cover;
`;

const MenuControlWrapper = styled(CardWrapper)`
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color) !important;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // menu style
  .ant-menu {
    background-color: initial;
    border-right: none !important;
    color: white !important;
    width: 100% !important;
  }
  .ant-menu-item {
    // border-radius: 15px;
    width: 100% !important;
    border-radius: 10px;
    &:hover {
      color: white !important;
      font-weight: bold;
      background-color: var(--primary-color-light) !important;
    }
  }

  .ant-menu-item::after {
    border: none !important;
  }

  .ant-menu-item-selected {
    color: white;
    background-color: var(--primary-color-light) !important;
  }
`;

export {
  AuthLayout,
  AuthLayoutCornerHeader,
  AuthContentLayout,
  AuthLayoutHeader,
  AuthLayoutContent,
  AuthLayoutSider,
  AuthLayoutSideFooter,
  MenuControlWrapper,
};
