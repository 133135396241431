import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { createUser, createUsers, deleteUser, getUser, getUsers, groupUpdateUsers, resetUserPassword, updateUser } from '@client/store/actions/user';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';
import { USER_FIELDS } from '@client/constants/db-fields/user';
import { findIndex } from 'lodash';

const {
  USER: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_USER]: false,
  [FIELDS.IS_CREATING_USER]: false,
  [FIELDS.IS_UPDATING_USER]: false,
  [FIELDS.IS_RESETTING_PASSWORD]: false,
  [FIELDS.USERS]: [],
  [FIELDS.USER]: null,
};
const extraReducers = {
  [resetUserPassword.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_RESETTING_PASSWORD]: true,
  }),
  [resetUserPassword.fulfilled]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_RESETTING_PASSWORD]: false,
  }),
  [resetUserPassword.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_RESETTING_PASSWORD]: false,
  }),
  [getUsers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_USER]: true,
    [FIELDS.USERS]: null,
  }),
  [getUsers.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.USERS]: action.payload,
  }),
  [getUsers.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.USERS]: null,
  }),
  [getUser.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_USER]: true,
    [FIELDS.USER]: null,
  }),
  [getUser.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.USER]: action.payload,
  }),
  [getUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.USER]: null,
  }),
  [createUser.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_USER]: true,
  }),
  [createUser.fulfilled]: (state, action) => {
    const existedUsers = state?.[FIELDS.USERS]?.content || [];
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_USER]: false,
      [FIELDS.USERS]: { content: [action.payload, ...existedUsers], total: (state?.[FIELDS.USERS]?.total || 0) + 1 },
    };
  },
  [createUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_CREATING_USER]: false,
  }),
  [createUsers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_USERS]: true,
  }),
  [createUsers.fulfilled]: (state, action) => {
    const existedUsers = state?.[FIELDS.USERS]?.content;
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_USERS]: false,
      [FIELDS.USERS]: {
        content: [...action.payload, ...existedUsers],
        total: (state?.[FIELDS.USERS]?.total || 0) + action.payload?.length,
      },
    };
  },
  [createUsers.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_CREATING_USERS]: false,
  }),
  [updateUser.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_USER]: true,
  }),
  [updateUser.fulfilled]: (state, action) => {
    const existedUsers = state?.[FIELDS.USERS]?.content;
    const id = action?.payload?.[USER_FIELDS.ID];
    const index = findIndex(existedUsers, { [USER_FIELDS.ID]: id });
    let newUsers = [...existedUsers];
    if (index !== -1) {
      newUsers = [...existedUsers.slice(0, index), action.payload, ...existedUsers.slice(index + 1)];
    }
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING_USER]: false,
      [FIELDS.USERS]: { ...state?.[FIELDS.USERS], content: newUsers },
    };
  },
  [updateUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING_USER]: false,
  }),

  [groupUpdateUsers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_USER]: true,
  }),
  [groupUpdateUsers.fulfilled]: (state, action) => {
    const existedUsers = state?.[FIELDS.USERS]?.content;
    let newUsers = [...existedUsers];
    action?.payload?.forEach(eachUser => {
      const id = eachUser?.[USER_FIELDS.ID];
      const index = findIndex(newUsers, { [USER_FIELDS.ID]: id });
      if (index !== -1) {
        newUsers = [...newUsers.slice(0, index), eachUser, ...newUsers.slice(index + 1)];
      }
    });

    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING_USER]: false,
      [FIELDS.USERS]: { ...state?.[FIELDS.USERS], content: newUsers },
    };
  },
  [groupUpdateUsers.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_UPDATING_USER]: false,
  }),

  [deleteUser.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING_USER]: true,
  }),
  [deleteUser.fulfilled]: (state, action) => {
    const existedUsers = state?.[FIELDS.USERS]?.content;
    const deletedId = action.payload;
    const newUsers = existedUsers?.filter(each => each?.[COMMON_FIELDS.ID] !== deletedId);
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING_USER]: false,
      [FIELDS.USERS]: { content: newUsers, total: state?.[FIELDS.USERS].total - 1 },
    };
  },
  [deleteUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_DELETING_USER]: false,
  }),
};

const userSlice = createSlice({
  name: REDUCER_STATE.USER.NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export { userSlice };
