import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { getCurrentUser, login, logout, updateProfile } from '@client/store/actions/auth';

const {
  AUTH: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_LOGGING_IN]: false,
  [FIELDS.IS_LOGGING_OUT]: false,
  [FIELDS.DATA]: null,
  [FIELDS.IS_LOGGED_IN]: false,
};
const extraReducers = {
  [login.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOGGING_IN]: true,
    [FIELDS.IS_LOGGED_IN]: false,
    [FIELDS.DATA]: null,
  }),
  [login.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOGGING_IN]: false,
    [FIELDS.IS_LOGGED_IN]: true,
    [FIELDS.DATA]: action.payload,
  }),
  [login.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_LOGGING_IN]: false,
    [FIELDS.IS_LOGGED_IN]: false,
    [FIELDS.DATA]: null,
  }),
  [getCurrentUser.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_VERIFYING]: true,
    [FIELDS.IS_LOGGED_IN]: false,
  }),
  [getCurrentUser.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_VERIFYING]: false,
    [FIELDS.IS_LOGGED_IN]: true,
    [FIELDS.DATA]: action.payload,
  }),
  [getCurrentUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_VERIFYING]: false,
    [FIELDS.IS_LOGGED_IN]: false,
    [FIELDS.DATA]: null,
    [FIELDS.ERROR]: action.error,
  }),
  [logout.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOGGING_OUT]: true,
  }),
  [logout.fulfilled]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOGGING_OUT]: false,
    [FIELDS.DATA]: null,
    [FIELDS.IS_LOGGED_IN]: false,
  }),
  [logout.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_LOGGING_OUT]: false,
    [FIELDS.IS_LOGGED_IN]: false,
    [FIELDS.DATA]: null,
    [FIELDS.ERROR]: action.error,
  }),

  [updateProfile.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_PROFILE]: true,
  }),
  [updateProfile.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_PROFILE]: false,
    [FIELDS.DATA]: { user: action.payload },
  }),
  [updateProfile.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_PROFILE]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const authSlice = createSlice({
  name: REDUCER_STATE.AUTH.NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export { authSlice };
