import BaseApi from './base';
import { isUndefined } from 'lodash';

class FormApi extends BaseApi {
  /**
   * Get forms
   * @returns {Promise<*>}
   */
  async getForms() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORMS);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get one form
   * @param {String} formId
   * @param {String} formAnswerId - only provided by assessor and student
   * @param {Boolean} isFetchFullExpanding
   * @returns {Promise<*>}
   */
  async getForm({ formId, formAnswerId, isFetchFullExpanding }) {
    const _self = this;
    try {
      const params = {};
      if (!isUndefined(formAnswerId)) params.formAnswerId = formAnswerId;
      if (!isUndefined(isFetchFullExpanding)) params.isFetchFullExpanding = isFetchFullExpanding;

      const res = await _self.client.get(_self.endpoints.FORM({ formId }), { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get list of answers
   * @param {Object} params
   * @return {Promise<*>}
   */
  async getFormAnswers(params = {}) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWERS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
  async getFormAnswer(formAnswerId) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create new assessment answer
   * @param {String} formId
   * @param {Object} answer
   * @param {Boolean} isSubmitBasic
   * @param {Boolean} isSubmitExpanding
   * @param {Object} element1CompletionStatus
   * @param {Object} element2CompletionStatus
   * @param {Object} element3CompletionStatus
   * @param {String} fileUUID
   * @return {Promise<*>}
   */
  async createFormAnswer({
    formId,
    answer,
    isSubmitBasic,
    isSubmitExpanding,
    element1CompletionStatus,
    element2CompletionStatus,
    element3CompletionStatus,
    fileUUID,
  }) {
    const _self = this;
    try {
      const body = {
        answer,
        isSubmitBasic,
        isSubmitExpanding,
        element1CompletionStatus,
        element2CompletionStatus,
        element3CompletionStatus,
        fileUUID,
      };
      const res = await _self.client.post(_self.endpoints.FORM_ANSWERS_IN_POST({ formId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update assessment answer
   * @param {string} formAnswerId
   * @param {Object} answer
   * @param {Boolean} isSubmitBasic
   * @param {Boolean} isSubmitExpanding
   * @param {Object} element1CompletionStatus
   * @param {Object} element2CompletionStatus
   * @param {Object} element3CompletionStatus
   * @param {Object} element4CompletionStatus
   * @param {String} fileUUID
   * @return {Promise<*>}
   */
  async updateFormAnswer({
    formAnswerId,
    answer,
    isSubmitBasic,
    isSubmitExpanding,
    element1CompletionStatus,
    element2CompletionStatus,
    element3CompletionStatus,
    element4CompletionStatus,
    fileUUID,
  }) {
    const _self = this;
    try {
      const body = {
        answer,
        isSubmitBasic,
        isSubmitExpanding,
        element1CompletionStatus,
        element2CompletionStatus,
        element3CompletionStatus,
        element4CompletionStatus,
        fileUUID,
      };
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER({ formAnswerId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
  async updateFormAnswerStatus({ formAnswerId, status }) {
    const _self = this;
    try {
      const body = {
        status,
      };
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_STATUS({ formAnswerId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
  async updateFormAnswerMarkingStatus({ formAnswerId, isMarkedAllBasic = undefined, isMarkedAllExpanding = undefined }) {
    const _self = this;
    try {
      const body = {
        isMarkedAllBasic,
        isMarkedAllExpanding,
      };
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_MARKING_STATUS({ formAnswerId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
  async getPrefilledFormData({ formId, offeringCode, subjectCode }) {
    const _self = this;
    try {
      const body = {
        offeringCode,
        subjectCode,
      };
      const res = await _self.client.get(_self.endpoints.FORM_INIT_ANSWER({ formId }), { params: body });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * download pdf
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async downloadPdf(formAnswerId) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER_PDF({ formAnswerId }), {
        responseType: 'blob',
      });
      return res?.data;
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * download form answer as html
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async downloadFormAsHtml(formAnswerId) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER_HTML({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Mark form answer
   * @param {Object} score
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async markFormAnswer({ formAnswerId, score }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_MARK({ formAnswerId }), { score });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Mark form answer
   * @param {Object} score
   * @param {string} mode
   * @return {Promise<*>}
   */
  async resetFormAnswer({ formAnswerId, mode }) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.FORM_ANSWER_RESET({ formAnswerId }), { mode });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Lock form answer
   * @param {string} formId
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async lockFormAnswer({ formId, formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_LOCK({ formId, formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Unlock form answer
   * @param {string} formId
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async unlockFormAnswer({ formId, formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_UNLOCK({ formId, formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete form answer
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async deleteFormAnswer(formAnswerId) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.FORM_ANSWER({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export default FormApi;
