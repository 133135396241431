import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { getCompletionData, getGitVersion, getMarkingData, getNumberOfAssessmentByStatusData } from '@client/store/actions/general';

const {
  GENERAL: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING]: false,
  [FIELDS.MARKING_DATA]: null,
  [FIELDS.COMPLETION_DATA]: null,
  [FIELDS.NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA]: null,
  [FIELDS.VERSION]: null,
};
const extraReducers = {
  [getGitVersion.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.VERSION]: null,
  }),
  [getGitVersion.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.VERSION]: action.payload,
  }),
  [getGitVersion.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.VERSION]: null,
  }),

  [getMarkingData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.MARKING_DATA]: null,
  }),
  [getMarkingData.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.MARKING_DATA]: action.payload,
  }),
  [getMarkingData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.MARKING_DATA]: null,
  }),

  [getNumberOfAssessmentByStatusData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA]: null,
  }),
  [getNumberOfAssessmentByStatusData.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA]: action.payload,
  }),
  [getNumberOfAssessmentByStatusData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA]: null,
  }),

  [getCompletionData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.COMPLETION_DATA]: null,
  }),
  [getCompletionData.fulfilled]: (state, action) => {
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING]: false,
      [FIELDS.COMPLETION_DATA]: action.payload,
    };
  },
  [getCompletionData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COMPLETION_DATA]: null,
  }),
};

const generalSlice = createSlice({
  name: REDUCER_STATE.GENERAL.NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export { generalSlice };
