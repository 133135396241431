import { createAsyncThunk } from '@reduxjs/toolkit';
import { generalApi } from '@client/services/api';
import { GET_COMPLETION_DATA, GET_GIT_VERSION, GET_MARKING_DATA, GET_NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA } from '@client/store/types/general';

/**
 * Get git version
 */
const getGitVersion = createAsyncThunk(GET_GIT_VERSION, async () => {
  return await generalApi.getGitVersion();
});

/**
 * Get marking data
 */
const getMarkingData = createAsyncThunk(GET_MARKING_DATA, async () => {
  return await generalApi.getMarkingData();
});

/**
 * Get number of assessments by status data
 */
const getNumberOfAssessmentByStatusData = createAsyncThunk(GET_NUMBER_OF_ASSESSMENTS_BY_STATUS_DATA, async () => {
  return await generalApi.getNumberOfAssessmentsByStatusData();
});

/**
 * Get completion data
 */
const getCompletionData = createAsyncThunk(GET_COMPLETION_DATA, async () => {
  return await generalApi.getCompletionData();
});

export { getGitVersion, getMarkingData, getCompletionData, getNumberOfAssessmentByStatusData };
