import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFY_TOKEN } from '@client/store/types/token';
import { tokenApi } from '@client/services/api';

/**
 * Verify token
 */
const verifyToken = createAsyncThunk(VERIFY_TOKEN, async token => {
  return await tokenApi.verifyToken(token);
});

export { verifyToken };
