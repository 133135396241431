import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { verifyToken } from '@client/store/actions/token';

const {
  TOKEN: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_VERIFYING]: false,
  [FIELDS.IS_VERIFIED]: false,
  [FIELDS.TOKEN]: null,
};
const extraReducers = {
  [verifyToken.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_VERIFIED]: false,
    [FIELDS.IS_VERIFYING]: true,
  }),
  [verifyToken.fulfilled]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_VERIFIED]: true,
    [FIELDS.IS_VERIFYING]: false,
  }),
  [verifyToken.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_VERIFIED]: false,
    [FIELDS.IS_VERIFYING]: false,
  }),
};

const tokenSlice = createSlice({
  name: REDUCER_STATE.TOKEN.NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export { tokenSlice };
