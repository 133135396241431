import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROOT_ROUTES } from '@client/constants/routes';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const PathTooltip = () => {
  const location = useLocation();
  const path = location?.pathname?.split('')?.join('');
  if (path === ROOT_ROUTES.HOME) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to display statistical data</p>
            <p>all data in this page is from the whole website not individual department</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ROOT_ROUTES.INSTITUTIONS) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to manage institutions</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ROOT_ROUTES.ASSESSMENTS) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to display available assessments</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ROOT_ROUTES.USERS) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to create users</p>
            <p>
              <strong>Create new</strong> is used to create 1 user
            </p>
            <p>
              <strong>Import</strong> is used to create multiple users by using template
            </p>
            <p>We can set subject(s) for each user, which is used to assign this user as the coordinator of that subject.</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default PathTooltip;
