import BaseApi from './base';

class TokenApi extends BaseApi {
  /**
   * Verify token
   * @param {String} token
   * @return {Promise<*>}
   */
  async verifyToken(token) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.VERIFY_TOKEN, { token });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { TokenApi };
