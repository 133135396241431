const MESSAGE = {
  LOADING: {
    DEFAULT: 'Loading...',
    LOGGING: 'Login...',
    INITIALIZE: 'Initializing...',
    GETTING: 'Getting data...',
    CREATING: 'Creating...',
    UPDATING: 'Updating...',
    DELETING: 'Deleting...',
    VERIFYING: 'Verifying...',
  },
  PLACEHOLDER: {
    EMAIL: 'Email',
    PASSWORD: 'Password',
    PASSWORD_CONFIRM: 'Confirm password',
    ROLE: 'Role',
    INSTITUTION: 'Institution',
    COHORT_NAME: 'Name',
    COHORT_YEAR: 'Year',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    STUDENT_ID: 'Student ID',
  },
  VALIDATION: {
    EMPTY_EMAIL: 'Please enter the email',
    EMPTY_PASSWORD: 'Please enter the password',
    EMPTY_CONFIRM_PASSWORD: 'Please confirm your password',
    EMPTY_FIRST_NAME: 'Please enter your first name',
    EMPTY_LAST_NAME: 'Please enter your last name',
    EMPTY_INSTITUTION: 'Please select your institution',
    EMPTY_ROLE: 'Please select your role',
    PASSWORD_NOT_MATCH: 'The two passwords that you entered do not match!',
  },
  CONFIRMATION: {
    RESET_ASSESSMENT: 'Are you sure to reset this assessment?',
    DELETE_ASSESSMENT: 'Are you sure to delete this assessment?',
    DELETE_INSTITUTION: 'Are you sure to delete this institution?',
    UN_ARCHIVE_INSTITUTION: 'Are you sure to un-archive this institution?',
    ARCHIVE_INSTITUTION: 'Are you sure to archive this institution (all data related to it will be freeze and un-editable)?',
    UN_ARCHIVE_COHORT: 'Are you sure to un-archive this cohort?',
    ARCHIVE_COHORT: 'Are you sure to archive this cohort (all data related to it will be freeze and un-editable)?',
    DELETE_COHORT: 'Are you sure to delete this cohort?',
    DELETE_USER: 'Are you sure to delete this user?',
  },
  PROMPT: {
    RESET_ASSESSMENT: 'Which part you want to reset?',
    FORM_ANSWER_UPDATED: 'Answers saved',
    USER_UPDATED: 'User updated',
    FORM_UPDATED: 'Assessment updated',
    RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY: 'The reset password email has been sent',
    TOKEN_INVALID: 'The token is invalid or expired',
  },
  TOOL_TIP: {
    ASSESSMENT_NOT_OPEN: 'Assessment does not open yet',
    ASSESSMENT_ANSWER_EXISTING: 'You already started this assessment',
    UNAUTHORIZED: 'You do not have permission',
    START_ASSESSMENT: 'Start assessment',
    PREVIEW_ASSESSMENT: 'Preview assessment',
    EDIT_ASSESSMENT: 'Edit assessment',
    EDIT_INSTITUTION: 'Edit institution',
    EDIT_COHORT: 'Edit cohort',
    MANAGE_COHORTS: 'Manage cohorts',
    MANAGE_USERS: 'Manage users',
    MANAGE_QUESTION_SET: 'Manage question set',
    ADD_ONE_USER: 'Add one user',
    ADD_MULTIPLE_USERS: 'Import users by Excel',
    UN_ARCHIVE_INSTITUTION: 'Un-archive institution',
    ARCHIVE_INSTITUTION: 'Archive institution',
    DELETE_INSTITUTION: 'Delete institution',
    UN_ARCHIVE_COHORT: 'Un-archive cohort',
    ARCHIVE_COHORT: 'Archive cohort',
    DELETE_COHORT: 'Delete cohort',
  },
};

export { MESSAGE };
